module.exports = [{
      plugin: require('/home/erwinsmit/projects/blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/erwinsmit/projects/blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900,"quality":100},
    },{
      plugin: require('/home/erwinsmit/projects/blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/erwinsmit/projects/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
